import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentGlobeFilled: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M8.904 16.5h6.192C14.476 19.773 13.234 22 12 22c-1.197 0-2.4-2.094-3.038-5.204l-.058-.294h6.192-6.192zm-5.838.001H7.37c.365 2.082.983 3.854 1.793 5.093a10.029 10.029 0 01-5.952-4.814l-.146-.279zm13.563 0h4.305a10.028 10.028 0 01-6.097 5.093c.755-1.158 1.344-2.778 1.715-4.681l.076-.412h4.306-4.306zm.302-6.5h4.87a10.055 10.055 0 01-.257 5H16.84a28.539 28.539 0 00.13-4.344L16.93 10h4.87-4.87zM2.198 10h4.87a28.211 28.211 0 00.034 4.42l.057.58H2.456a10.047 10.047 0 01-.258-5zm6.377 0h6.85a25.838 25.838 0 01-.037 4.425l-.062.575H8.674a25.979 25.979 0 01-.132-4.512L8.575 10h6.85-6.85zm6.37-7.424l-.109-.17A10.027 10.027 0 0121.372 8.5H16.78c-.316-2.416-.956-4.492-1.837-5.923l-.108-.17.108.17zm-5.903-.133l.122-.037C8.283 3.757 7.628 5.736 7.28 8.06l-.061.44H2.628a10.028 10.028 0 016.414-6.057l.122-.037-.122.037zM12 2.002c1.319 0 2.646 2.542 3.214 6.183l.047.315H8.739C9.28 4.691 10.643 2.002 12 2.002z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentGlobeFilled
